.sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  background: var(--bg-navbar);
  left: 0;

  .MenuItem {
    position: relative;

    a {
      display: flex;
      justify-content: start;
      align-items: center;

      span {
        margin-left: 10px;
      }

      svg {
        fill: #ffffff59;
      }
    }

    a.active {
      span {
        color: #fff !important;
      }

      &::before {
        content: '';
        position: absolute;
        left: 10px;
        top: 18px;
        width: 5px;
        height: 5px;
        background: #20ace8;
        border-radius: 100%;
      }

      svg {
        fill: #fff;
      }
    }
  }

  .footer-sidebar {
    height: 58px;
    display: flex;
    justify-content: start;
    padding-left: 14px;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 7px;
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      width: 92.5%;
    }

    .Button {
      background: none;
      border: 0;
      padding: 0;

      .nickname {
        margin-right: 9px;
        background: #208ee8 !important;
        border-radius: 50%;
        min-height: 34px;
        min-width: 34px;
        font-weight: 600;
        font-size: 13px;
        color: #fff;
        line-height: 34px;
      }

      .NameWithRole {
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        margin-right: 13px;

        .title {
          line-height: 15px;
          font-size: 12px;
          color: #fff !important;
          font-weight: bold;
          margin: 0;
          width: 88px;
          overflow: hidden;
          text-overflow: ellipsis;
          letter-spacing: 0.7px;
        }

        .role {
          color: rgba(255, 255, 255, 0.55) !important;
          font-size: 10px;
        }
      }
    }
  }
}
