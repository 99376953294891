.Settings {
  > .title {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.44px;
    margin-bottom: 41px;
  }

  .other {
    margin-top: 37px;
  }
}

@media screen and (max-width: 991px) {
  .Settings {
    padding-bottom: 38px;
  }
}

@media screen and (max-width: 768px) {
  .PersonalInfo {
    margin-bottom: 0;
  }
  .PersonalInfo .Card .CardContent .icon {
    min-width: 62px;
    min-height: 62px;
    width: 62px;
    height: 62px;
    padding: 0;
  }
  .PersonalInfo .Card .CardContent .info .name {
    font-size: 20px;
  }
  .PersonalInfo .Card .CardContent .info .role {
    font-size: 12px;
  }
  .PersonalInfo .Card .CardContent .info .email {
    font-size: 14px;
    margin-top: 1px;
  }
  .PersonalInfo .Card .CardContent .icon span {
    font-size: 23px;
  }
  .Units .Card .CardContent .Temperature .btnUnit {
    width: 50%;
  }
  .Units .Card .CardContent .Speed .btnUnit {
    width: 50% !important;
  }
  .Units {
    margin-top: 19px;
    width: 100%;
  }
  .ChangePassword {
    margin-top: 19px;
    width: 100% !important;
  }
  .other {
    margin-top: 29px;

    .OtherSettings {
      width: 100%;
    }
  }
}
