.ScheduledHeating {
  margin-top: 32px;
  margin-bottom: 35px;
  border-radius: 2px;
  box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);
  background: var(--bg-white);

  .title {
    font-size: 14px;
  }

  .ScheduledBody {
    padding: 21px 26px 20px;

    .ScheduledOption {
      .SelectDevice {
        margin-right: 10px;
        min-width: 130px;
        max-width: 200px;
        max-height: 34px;
        border-radius: 4px;
        box-shadow: 0 2px 0 0 rgba(77, 80, 96, 0.08);
        border: 1px solid #e0e0e0;
        font-size: 13px;
        color: var(--text-title-color) !important;

        .ant-select-clear {
          background: transparent;
          color: var(--text-white)
        }
      }

      .DatePicker {
        margin-right: 10px;
        width: 140px;
        font-size: 13px !important;
        border-radius: 4px !important;
        box-shadow: 0 2px 0 0 rgba(77, 80, 96, 0.08) !important;
        border: solid 1px #e0e0e0 !important;

        * {
          font-size: 13px !important;
          color: var(--text-title-color) !important;
        }
      }
    }

    .BtnAddHeating {
      width: 160px;
      height: 34px;
      flex-grow: 0;
      border-radius: 4px;
      box-shadow: 0 2px 0 0 rgba(77, 80, 96, 0.08);
      border: solid 1px #20ace8 !important;
      background-color: var(--bg-white);
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--text-primary);

      &:focus {
        background: var(--text-primary);
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ScheduledBody {
    padding: 15px 11px 20px 12px !important;
  }
  .ScheduledOption {
    width: 100%;
    flex-wrap: wrap;

    > div {
      width: 47.5%;
    }

    .DatePicker {
      width: 100% !important;
    }
  }
  .SelectDevice {
    margin-bottom: 20px;
    margin-right: 0 !important;
    width: 100% !important;
    min-width: auto !important;
    max-width: unset !important;
  }
}
