.Device {
  padding: 10px 10px 0;

  .title {
    font-size: 13px;
    margin-bottom: 12px;
  }

  .CardDevice {
    min-width: 383px;
    min-height: 383px;
    max-height: 383px;
    background: var(--bg-white);

    .Tabs {
      padding: 0 !important;
      margin: 0 !important;

      .TabPane {
        padding: 0 !important;
        margin: 0;
      }

      .TabItem {
        min-height: 17px;
        padding: 0px 0px 0px 41px;
        margin-right: 0
      }

      .ant-tabs-tab {
        width: 100%;
        margin-right: 0;
        padding: 14px 0;
      }

      .ant-tabs-nav-list {
        width: 100%;
      }
    }

    .DeviceSec1 {
      margin-bottom: 17px;

      .DeviceSec1_1 {
        .bg-none {
          margin-right: 12px;
          height: 34px;
        }

        .Current {
          font-size: 11px;
          margin-bottom: 2px;
        }

        .Temp {
          font-size: 18px;
        }
      }

      .DeviceSec1_2 {
        .btn-device {
          line-height: 0;
          margin-right: 29.7px !important;

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }

    .ButtonRssVertical {
      width: 25.792px;
      height: 19.056px
    }

    .BtnVideoVertical {
      width: 25.792px;
      height: 21.056px;
      margin: 0 27.9px 0 26.4px !important
    }

    .horizontalPadding {
      padding: 0 15px
    }

    .verticalPadding {
      padding: 0 17px 0 26px
    }

    .enclosure_temp_vertical {
      margin-right: 49px;
      margin-left: 49px;
      width: 109px
    }

    .fuel_vertical {
      margin-right: 49px;
      width: 53px
    }

    .energy_vertical {
      margin-right: 66px !important;
      width: 66px !important;
    }

    .set_temp_vertical {
      margin-right: 40px;
      width: 178px
    }

    .DeviceSec2 {
      margin-bottom: 19px;
      justify-content: space-between;

      .BtnSnowSensor {
        border: 0;
        border-radius: 4px;
        text-transform: uppercase;
        letter-spacing: .154px;
        background-color: #f4f4f4;
        color: #9c9c9c;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        height: 42px;
        min-width: 176px;
        width: 100%;

        span {
          height: 16px;
          line-height: 17px;
          margin-left: 8px;
        }
      }

      .BtnFanWidth {
        min-width: 163px;
        width: 100%;
        margin-left: 13px;
      }

      .BtnActive {
        color: #fff;
        text-transform: uppercase;
        border: 0;
        border-radius: 4px;
        background-color: #20ace8;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.442px;
        font-size: 13px;
        height: 42px;

        span {
          height: 16px;
          line-height: 17px;
          margin-left: 8px;
        }
      }
    }

    .CardFooter {
      margin-bottom: 40px;

      .SetTempVal {
        font-size: 22px;
      }

      .temp_device {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span {
          font-size: 17px;
          display: flex;
          align-self: start;
          margin-top: 5px;
        }
      }
    }

    .DeviceTitle {
      font-size: 12px;
    }

    .BtnDetails {
      color: #20ace8;
      font-size: 13px;
      padding: 0;
      background: none;
      height: auto;
      display: block;
      margin: auto;
    }
  }

  &.Vertical {
    .CardDevice {
      min-height: auto;
    }

    .Tabs {
      .ant-tabs-tab {
        width: 166px;
      }

      .TabItem {
        padding: 0 0 0 20px;
      }

      .ant-tabs-nav-list {
        width: auto;
      }

      .temp_device {
        span {
          font-size: 18px;
          letter-spacing: -1.9px;
        }
      }
    }

    .Current {
      margin-bottom: 12px !important;
    }

    .Temp {
      letter-spacing: -1.9px;
    }

    .DeviceTitle {
      margin-bottom: 12px;
    }

    .DeviceSec1_2 {
      position: absolute;
      right: 20px;
      top: 9.7px;
    }

    .DeviceSec2 {
      margin-bottom: 0;
    }

    .BtnDetails {
      font-family: 'SFProText' !important;
      height: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 23px;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &.DeviceFault {
    .ant-tabs-ink-bar {
      background: none !important;
    }

    svg {
      fill: #CCC;
    }
  }
}

@media screen and (max-width: 1349px) {
  .device {
    min-width: 50%;
  }
  .Device {
    min-width: 49%;
    padding: 12px 0 0 11px;
    margin: auto;
  }
}

@media screen and (max-width: 991px) {
  .device {
    min-width: 100% !important;
  }
  .device-footer {
    overflow-x: auto;

    .country-weather {
      margin-right: 20px;
    }
  }
  .device .device-content .d-body {
    margin-right: 25px;
    margin-left: 25px;
  }
}

@media screen and (max-width: 768px) {
  .Device {
    min-width: 100%;
    margin-bottom: 25px;
  }
  .device {
    min-width: 100%;
  }
}

@media screen and (max-width: 415px){
  .Device .CardDevice {
    width: 100%;
    min-width: unset;
  }
  .Device .CardDevice .DeviceSec2 .BtnSnowSensor {
    width: 50%;
    min-width: unset;
    padding: 0;
    font-size: 10px;
  }
}

.faultTooltip {
  .ant-tooltip-inner {
    box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.05);
  }
}

.ant-tabs-tab-disabled {
  div {
    color: #979797 !important;
  }

  svg {
    fill: #CCC !important;
  }
}
