.Fuel {
  min-width: 236px;

  .title {
    font-size: 12.09px;
    margin-bottom: 9px;
    color: var(--text-color-black);
  }

  .Card {
    box-shadow: 10px 3px 20px 0 rgba(77, 80, 96, 0.06);
    background: var(--bg-white);
    height: 90px;

    .Body {
      padding-right: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 57px;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0px;
        width: 1px;
        height: 57px;
        background: #edecec;
      }

      .fuel-icon {
        font-size: 22px;
        width: 22px;
        height: 28px;
      }

      .Content {
        margin-left: 11px;

        .title {
          font-size: 20px;
          font-weight: normal;
          margin: 0 !important;
          margin-bottom: 4px !important;
        }

        .paragraph {
          font-size: 13px;
          margin: 0;
        }
      }
    }
  }
}
