.ChangePassword {
  .title {
    font-size: 13px;
    margin-bottom: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    bottom: -29px;
    width: 100%;
    text-transform: lowercase;
    font-size: 12px;
  }

  .Card {
    box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);

    .CardContent {
      .newPasswordSection {
        margin-top: 22px;

        .Item {
          margin-right: 32px !important;

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }

      label {
        font-size: 12px;
        color: #979797;
        line-height: 14px;
      }

      .Item {
        line-height: 14px;

        > * {
          line-height: 14px;
        }
      }

      .Input {
        max-width: 320px;
        width: 320px;
        height: 48px;
        border-radius: 4px;
        border: solid 1px #e0e0e0;
        font-size: 16px;
        color: #000;
        background: transparent;

        input {
          background: transparent;
        }
      }

      .Button {
        min-width: 148px;
        height: 48px;
        border-radius: 4px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        background: #f9f9f9;
        box-shadow: none;

        span {
          color: #9c9c9c;
          font-size: 13px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }

    }
  }
}

@media screen and (max-width: 1420px) {
  .ChangePassword {
    .Card {
      .CardContent {
        .newPasswordSection {
          flex-direction: column;
          width: 100%;
          align-items: start !important;
        }

        .Item {
          margin-bottom: 20px !important;
          width: 100%;

          .Input {
            width: 100%;
            max-width: unset;
          }
        }

        .Button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .ChangePassword {
    .Card {
      .CardContent {
        .newPasswordSection {
          flex-direction: column;
          width: 100%;
          align-items: start !important;
        }

        .Item {
          width: 100%;

          .Input {
            width: 100%;
            max-width: unset;
          }
        }

        .Button {
          width: 100%;
        }
      }
    }
  }
}
