.device {
  min-width: 383px;
  margin-bottom: 31px;

  .device-title {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 7px;
    color: var(--text-title-color);
    height: 17px;
    line-height: 17px;
  }

  .device-content {
    background: var(--bg-white);
    box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);

    .d-image {
      height: 238px;

      img {
        min-height: 160px;
        height: 160px;
        object-fit: cover;
      }
    }

    .devices {
      height: 40px;
      margin: 0 35.6px 0 19px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      span {
        color: #979797;
        font-size: 14px;
        margin: 0;
        margin-right: 10px;
        background: #eeeeee;
        padding: 1px 6px;
        border-radius: 4px;
        &.emptyDevices {
          background: transparent;
        }
      }
    }

    .d-body {
      margin: 16px 35.6px 0px 19px;
      min-height: 33px;

      .d-body-title {
        h6 > * {
          color: var(--text-title-color) !important;
          font-size: 20px;
          margin-bottom: 4px;
          font-weight: 600;
        }
      }

      .temprature {
        font-size: 18px;
        color: var(--text-title-color);

        span {
          width: 43px;
          height: 21px;
          font-weight: 500;
          margin-right: 13.8px;
        }
      }
    }

    .device-footer {
      margin: 0 19px 0 19px;
      padding: 14.54px 0 18px;

      .country-weather {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        font-size: 13px;
        color: #979797;

        .country-weather-icon {
          margin-bottom: 9px;
          font-size: 21px;
        }

        .country-weather-title {
          font-weight: 500;
        }

        &.country-weather-active {
          .country-weather-title {
            color: var(--text-primary) !important;
          }
        }
      }
    }
  }
}
