.btnSetTemp {
  width: auto;
  padding: 3px 6px!important;
  height: auto;
  right: 30%;
  top: 2px;
  border-radius: 4px;
  background: var(--btn-custom);
  border: 1px solid var(--btn-custom-border);
}
