body {
  background: #fff;
}

* {
  font-family: "SFProText";
}

input[type="password"] {
  -webkit-text-security: square !important;
  -moz-text-security: square !important;
  -o-text-security: square !important;
  text-security: square !important;
}

.Login {
  overflow-y: hidden;

  .left-bg {
    background: url("../../assets/login.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }

  div {
    line-height: unset !important;
    min-height: auto;
  }

  label {
    height: 13px;
  }

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0px;
  }

  .LoginContent {
    width: 430px;
    margin: 83px auto 0;

    .LoginHeader {
      margin-bottom: 70px;

      img {
        width: 120px;
      }

      h3 {
        margin-top: 23px;
        margin-bottom: 8px;
        font-size: 24px;
        color: #3f3f3f;
        line-height: unset;
      }

      p {
        font-size: 14px;
        color: #979797;
      }
    }
  }

  .SelectPaltform.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }

  .Privacy {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    display: block;
    margin-top: 76px;

    span {
      border-bottom: 1px solid #979797;
    }
  }
}

.h-48px {
  height: 48px;
  border-radius: 5px;
}

.ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.labelForm {
  font-size: 11px;
  text-transform: uppercase;
}

@media screen and (max-width: 430px) {
  .Login {
    .LoginContent {
      width: auto;
      padding: 0 37px 0 35px;

      .LoginHeader {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}
