.details-header {
  margin-top: 23px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1437px) {
  .LocationSetting {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1030px) {
  .LocationSetting {
    width: 100%;

    .windFactorCol {
      width: 100%;
      margin-bottom: 6px;

      .WindTimer {
        .title {
          font-size: 10px;
        }

        min-width: 100%;

        .Body {
          &::after {
            content: none;
          }

          justify-content: start !important;
          align-items: center !important;

          .wind_countdown {
            margin-left: 18px;
            margin-right: auto;
          }

          .btns {
            flex-direction: row !important;

            button {
              margin-bottom: 0;
              margin-right: 7px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .fuelCol {
      .Fuel {
        > .title {
          display: none !important;
        }

        min-width: auto;
        width: 100%;

        .ant-card-body {
          padding: 0px !important;
          height: 100%;
        }

        .Card {
          height: 55px!important;
        }

        .Body {
          padding: 0;

          &::after {
            content: none;
          }

          svg {
            width: 17.3px;
            height: 22px;
          }

          .Content {
            .title {
              font-size: 15px !important;
            }

            .paragraph {
              font-size: 12px !important;
            }
          }
        }
      }

      width: 50%;
    }

    .energyCol {
      .Energy {
        > .title {
          display: none !important;
        }

        min-width: auto;
        height: 56px !important;
        width: 100%;

        .Body {
          padding-right: 0;

          svg {
            width: 16.1px;
            height: 22px;
          }
        }

        .ant-card-body {
          padding: 9px 12px 10px 0px !important;
        }

        .Content {
          .title {
            font-size: 15px !important;
          }

          .paragraph {
            font-size: 12px !important;
          }
        }
      }

      width: 50%;
    }
  }
}

@media screen and (max-width: 991px) {
  .site-layout .site-layout-background {
    padding: 13px 17px 25px 16px;
  }
  .MasterControls {
    margin-right: 17px !important;

    .title {
      font-size: 10px !important;
    }
  }
  .OveralTemperature {
    margin-right: unset !important;
    width: 182px;

    .title {
      font-size: 10px !important;
    }

    .Card {
      width: auto !important;
    }
  }
  .MasterControls .btns-on-off .btn-on {
    padding: 14px 20px 14px 10px !important;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    width: auto !important;
    height: 44px !important;

    .btn-icon {
      margin-right: 13.82px;

      svg {
        width: 19px;
        height: 22px;
      }

      margin-bottom: 0 !important;
    }
  }
  .MasterControls .btns-on-off .btn-off {
    padding: 14px 20px 14px 10px !important;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    width: auto !important;
    height: 44px !important;

    .btn-icon {
      margin-right: 10px;

      svg {
        width: 24px;
        height: 24px;
      }

      margin-bottom: 0 !important;
    }
  }
  .OveralTemperature .Card {
    height: 44px !important;

    .ant-card-body {
      padding: 8px !important;
    }

    .ButtonCustom {
      width: 28px !important;
      height: 28px !important;
    }

    .set-temp {
      font-size: 14px !important;
      color: #000000;
    }
  }
}

@media screen and (max-width: 410px){
  .LocationSetting {
    .fuelCol {
      width: 100%;
      margin-bottom: 6px;
    }
    .energyCol {
      width: 100%;
    }
  }
}
