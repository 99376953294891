:root {
    --bg-main: #f9f9f9;
    --bg-white: #ffffff;
    --bg-btn: #e5e5e5;
    --bg-divider: #dfdfdf;
    --bg-navbar: #0c1938;
    --bg-navbar-header: #283553;
    --text-title-color: #3f3f3f;
    --text-subtitle-color: #6d6d6d;
    --text-color: #979797;
    --text-color-black: #000000;
    --text-primary: #20ace8;
    --text-white: #ffffff;
    --text-a6a6a6: #a6a6a6;
    --text-title-zone: #3f3f3f;
    --btn-custom: #ffffff;
    --btn-custom-border: rgb(238, 238, 238);
    --bg-table-head: #f6f6f6;
    --bg-wind-start: #f4f4f4;
}

@font-face {
    font-family: 'SFProText';
    src: url('./assets/fonts/FontsFree-Net-SFProText-Bold.ttf');
}

@font-face {
    font-family: 'SFProTextRegular';
    src: url('./assets/fonts/FontsFree-Net-SFProText-Regular.ttf');
}

@font-face {
    font-family: 'GraphikLight';
    src: url('./assets/fonts/GraphikLight.otf');
}

body {
    margin: 0;
    background: var(--bg-main) !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
    cursor: pointer;
}

button {
    overflow: visible!important;
}
.h-34 {
    height: 34px !important;
}
.h-22 {
    height: 22px !important;
}
.bg-main {
    background: var(--bg-main) !important;
}

.bg-white {
    background-color: var(--bg-white) !important;
}

.bg-pri {
    background-color: #20ace8 !important;
}

.text-20ace8 {
    color: var(--text-primary) !important;
}

.bg-divider {
    background: var(--bg-divider) !important;
}

.mr-1-75 {
    margin-right: 1.75rem;
}

.mr-2-75 {
    margin-right: 2.75rem;
}

.mr-2-625 {
    margin-right: 2.625rem;
}

.mr-2-5625 {
    margin-right: 2.5625rem;
}

.text-20ace8 {
    color: #20ace8 !important;
}

.mb-17 {
    margin-bottom: 17px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.mb-28 {
    margin-bottom: 28px !important;
}

.mt-19 {
    margin-top: 19px !important;
}

.mb-47 {
    margin-bottom: 47px !important;
}

.bg-sec {
    background-color: #f4f4f4 !important;
}

.text-3f3f3f {
    color: var(--text-title-color) !important;
}

.bg-on {
    background: #3ccab0 !important;
    color: #fff !important;
    outline: none;
    border: none !important;
}

.bg-off {
    background: var(--bg-btn) !important;
    outline: none;
    border: none !important;
}

.text-6d6d6d {
    color: var(--text-subtitle-color) !important;
}

.text-000 {
    color: var(--text-color-black);
}

.text-ccc {
    color: #cccccc !important;
}

.text-1d1d1f {
    color: #1d1d1f !important;
}

div {
    line-height: initial;
}

.ant-card-body {
    background-color: var(--bg-white) !important;
}

/*.Card {*/
/*  background-color: var(--bg-white) !important;*/
/*}*/
.ant-typography.title,
h3, h5, h6 {
    color: var(--text-title-color);
}

.ant-typography {
    color: var(--text-color-black) !important;
}

.device .device-content .d-body .d-body-title h6 > * {
    color: var(--text-title-color) !important;
}

.text-title-zone {
    color: var(--text-title-zone) !important;
}

.text-color-black {
    color: var(--text-color-black) !important;
}

.bg-table {
    background: var(--text-color);
}

.text-a6a6a6 {
    color: var(--text-a6a6a6) !important;
}

.bg-none {
    background: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #208ee8 !important;
}

.ant-tabs-ink-bar {
    background: #208ee8 !important;
}

.text-9c9c9c {
    color: #9c9c9c !important;
}

.text-979797 {
    color: #979797 !important;
}

.text-12px {
    font-size: 12px !important;
}
.text-14px {
    font-size: 14px !important;
}
.text-20px {
    font-size: 20px!important;
}
.mb-12 {
    margin-bottom: 12px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mr-12px {
    margin-right: 12px !important;
}

.mb-31 {
    margin-bottom: 31px;
}

.ant-modal-content, .ant-modal-header {
    background-color: var(--bg-white) !important;
}

.ant-table-pagination.ant-pagination {
    margin: 16px !important;
}

.ant-table-tbody > tr > td {
    color: var(--text-a6a6a6);
}

.SelectDevice, .select-device__control {
    background: transparent !important;
}

.TextAreaHeating {
    background: transparent !important;
}

.ant-table-tbody tr td {
    background: transparent !important;
}

.ant-table-tbody tr:hover td {
    background: #f9f9f9 !important;
}

.mr-1-5625 {
    margin-right: 1.5625rem;
}

.mt-18 {
    margin-top: 18px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mr-35 {
    margin-right: 35px !important;
}

.width-67 {
    width: 67px;
}

.ml-13 {
    margin-left: 13px;
}

.ml-27 {
    margin-left: 27px !important;
}

.mb-21 {
    margin-bottom: 21px !important;
}

.mb-11 {
    margin-bottom: 11px !important;
}

.margin-divider {
    margin: 28px 0 35px 0 !important
}

.h-10 {
    height: 10px;
}
