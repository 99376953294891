.SelectPlatform {
  min-width: 214px;
  max-width: 214px;
  min-height: 40px;
  box-shadow: 0 2px 0 0 rgba(77, 80, 96, 0.08);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 10px;
  font-size: 13px;
  height: 40px;
  color: var(--text-title-color) !important;
  .ant-select-selector {
    height: 100% !important;
    border: 0!important;
    padding-top: 3px!important;

  }
}
