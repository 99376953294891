thead[class*="ant-table-thead"] th {
  background-color: var(--bg-table-head) !important;
  height: 36px;
  border-radius: 0 !important;
  color: #787878;
  font-size: 12px;
  padding: 10px 31px;
  border: 0 !important;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: inherit !important;
}

.ant-table-tbody {
  background: var(--bg-white) !important;
}

.ant-table-tbody > tr > td {
  border: 0;
}

@media screen and (max-width: 768px) {
  thead[class*="ant-table-thead"] th {
    padding: 10px 0px 10px 13px;
  }
}
