.OveralTemperature {
  margin-right: 32px;

  .title {
    font-size: 12.09px;
    margin-bottom: 9px;
  }

  .Card {
    width: 212px;
    height: 90px;
    box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);
    background-color: var(--bg-white);

    .Body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      .overal-temp-btn {
        width: 33px;
        height: 33px;
        padding: 0;
        border-radius: 4px;
        box-shadow: 0 2px 0 0 rgba(77, 80, 96, 0.08);
        border: solid 1px #e0e0e0;
        background-color: #ffffff;
      }

      .set-temp {
        font-size: 24px;
        font-weight: 500;
        color: var(--text-color-black);
      }

    }
    .BtnOverallSetTemp {
      width: auto;
      padding: 3px 6px !important;
      height: auto;
      right: 41%;
      border-radius: 4px;
      background: var(--btn-custom);
      border: 1px solid var(--btn-custom-border);
      bottom: -30px;
    }
  }
}
