.Units {
  .title {
    font-size: 13px;
    margin-bottom: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Card {
    box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);

    .CardContent {
      .title {
        font-size: 12px;
        margin-bottom: 14px;
      }

      .btnsUnit {
        .btnUnit {
          margin-right: 13px;

          &:last-child {
            margin-right: 0;
          }

          height: 43px;
          border: none;
          border-radius: 4px;
          font-size: 15px;
          background: #f4f4f4;
          width: -webkit-fill-available;
        }
      }

      .Temperature {
        margin-bottom: 21px;

        .btnUnit {
          width: 109.4px;
        }
      }

      .Speed {
        .btnUnit:first-child {
          width: 100px;
        }

        .btnUnit:last-child {
          width: 120px;
        }
      }
    }
  }
}
