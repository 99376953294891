.WindTimer {
  min-width: 285px;

  .title {
    font-size: 12.09px;
    margin-bottom: 9px;
  }

  .Card {
    box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);

    .Body {
      position: relative;
      padding-right: 14px;
      height: 57px;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 57px;
        background: #edecec;
      }

      .wind-icon {
        font-size: 28px;
      }

      .wind_countdown {
        margin-left: 15px;
        margin-right: 43px;
        font-size: 13px;
        text-align: center;

        .countdown {
          font-size: 20px;
        }
      }

      .btns {
        button {
          margin-bottom: 7px;

          &:last-child {
            margin-bottom: 0;
          }

          font-size: 14px;
          width: 62px;
          height: 32px;
          text-align: center;
          padding: 0;
          border: 0;
          color: #fff;
          border-radius: 4px;
        }
      }
    }
  }
  .windStart {
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--bg-wind-start);
    width: 59px;
    height: 56px;
  }
}
