.footerBtn {
  padding: 14.30px 30px 13px 31px !important;
  border: none;
  height: 43px;
  font-size: 13px;
  border-radius: 4px;
  margin-left: 10px;
}

.ant-modal-header {
  border-color: #dfdfdf;
}

.ant-modal-footer {
  border: none;
}

.SelectDevice {
  min-width: 214px;
  max-width: 214px;
  min-height: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(77, 80, 96, 0.08);
  border: 1px solid #e0e0e0;
  font-size: 13px;
  color: var(--text-title-color) !important;

  .ant-select-clear {
    background: transparent;
    color: var(--text-white)
  }
}

.DatePicker {
  width: 140px;
  height: 40px;
  font-size: 13px !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 0 0 rgba(77, 80, 96, 0.08) !important;
  border: solid 1px #e0e0e0 !important;

  * {
    font-size: 13px !important;
    color: var(--text-title-color) !important;
  }
}

.TextAreaHeating {
  border-color: #e0e0e0;
  box-shadow: 0 2px 0 0 rgba(77, 80, 96, 0.08);
  border-radius: 4px;
  height: 119px !important;
}
