* {
  font-family: "SFProTextRegular" !important;
  font-weight: normal;
}
body {
  font-family: "SFProText";
}
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
  padding: 21px 28px 21px 22px;
  background: #f9f9f9;
  min-height: 0;
  height: 100%;
  overflow: auto;
}
.logo {
  color: #fff;
  height: 50px;
  background: var(--bg-navbar-header);
  padding: 15px 27px;
  position: relative;

  .logo-v {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 8px;
    color: #aaa;
  }
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
@media screen and (max-width: 991px) {
  .site-layout {
    margin: auto !important;
    width: 100%;
    padding-top: 54px;
    padding-bottom: 20px;
  }
}
