.Trigger {
  border-right: 1px solid #e9e9e9;
  min-height: 61px;
  padding-right: 55px;
  padding-left: 56px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 33%;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border: none;
    padding-right: 0;
  }

  .Details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
    font-size: 18px;
    width: max-content;

    .Temp {
      margin-left: 34px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -19px;
        background: #c4c4c4;
        border-radius: 100%;
        width: 7px;
        height: 7px;
      }
    }
  }

  .Name {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .Trigger {
    padding-right: 26px;
    padding-left: 10px;

    &:last-child {
      border: none;
      padding-right: 26px;
    }

    .Details {
      font-size: 13px;
    }

    .Temp {
      margin-left: 5px !important;

      &::before {
        left: -4px !important;
        top: 7px !important;
        width: 3px !important;
        height: 3px !important;
      }
    }

    .Name {
      font-size: 12px;
    }
  }

}
