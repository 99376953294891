.camActive {
  background: #000 !important;
  height: 28px;
  width: 111px;
  border-radius: 2px;
  border: none;
  padding-left: 27px;

  span {
    font-size: 13px;
    color: #fff;
    position: relative;
    font-weight: 500;

    &::before {
      content: '';
      position: absolute;
      background: #ff3232;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      left: -12px;
      top: 6px;
    }
  }
}
