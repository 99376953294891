.Header {
  position: fixed;
  height: 54px;
  top: 0;
  z-index: 3;
  width: 100%;
  background: var(--bg-navbar);
  padding: 11px 22px 9px 28px;

  .icon {
    width: 34px;
    height: 34px;
    flex-grow: 0;
    border-radius: 100%;
    background-color: #208ee8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    span {
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      font-stretch: normal;
      height: 14.6px !important;
    }
  }
}
