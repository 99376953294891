.WindFactorTriggers {
  .Card {
    padding: 14px 58px 15px 19px;
    box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);
    background: var(--bg-white);

    .-trigger {
      overflow-x: auto;
      width: 100%;
    }
  }

  .title {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .WindFactorTriggers {
    .Card {
      padding: 0 10px;
      box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);
      width: 100%;
    }

    .title {
      font-size: 13px;
    }

    margin-bottom: 25px;
  }

}
