.highcharts-figure, .highcharts-data-table table {
  min-width: 360px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #EBEBEB;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-a11y-proxy-button {
  z-index: 1 !important;
  background: transparent !important;
  color: var(--text-a6a6a6);
}

.highcharts-background {
  fill: var(--bg-white);
}

.chartContainer {
  width: 100% !important;
  display: block !important;
  overflow: hidden !important;
  padding: 0;
}

.highcharts-title {
  fill: var(--text-a6a6a6) !important;
}
