.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;

  h3 {
    font-size: 22px;
    margin: 0
  }

  .header-form {
    margin-right: 10px;
    margin-bottom: 0;
    width: 332px
  }

  .header-input {
    height: 40px;
    border-radius: 6px;
    border: solid 1px #e0e0e0;
    color: rgb(151, 151, 151);
    font-size: 20px;
    border-color: #e0e0e0;
    padding: 9px 13px;

    .search-icon {
      margin-right: 8px;
    }

    &.mobile {
      position: absolute;
      width: 200px;
      right: -10px;
      top: -8px;
      z-index: 0;
    }
  }

  .header-btn {
    width: 37px;
    height: 37px;
    border-radius: 4px;
    text-align: center;
    padding: 0;
    color: #979797;
    font-size: 20px
  }
}

@media screen and (max-width: 991px) {
  .content-header {
    h3 {
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.3213px;
    }
  }
}
