.ButtonCustom {
  border: 1px solid var(--btn-custom-border);
  background: var(--btn-custom);
  width: 34px;
  height: 34px;
  color: var(--text-color-black);
  font-size: 28px;
  font-weight: 500;
  border-radius: 4px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
