.add-device {
  position: fixed;
  right: 29px;
  bottom: 32px;
  width: 50px;
  height: 50px;
  border-radius: 38px;
  box-shadow: 0 4px 14px 0 rgba(32, 172, 232, 0.5);
  background-color: #20ace8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15.6px;
  color: #ffffff;
  border: 0;
}

@media screen and (max-width: 991px) {
  .add-device {
    position: relative;
    right: 0;
    display: flex;
    margin-left: auto;
    margin-top: 20px;
  }
}

