.MasterControls {
  margin-right: 32.5px;

  .title {
    font-size: 12.09px;
    margin-bottom: 9px;
  }

  .btns-on-off {
    .btn-on {
      display: flex;
      margin-right: 6.5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0;
      width: 62.5px;
      height: 90px;
      border-radius: 4px;
      box-shadow: 0 7px 14px 0 rgba(60, 202, 176, 0.3);

      .btn-icon {
        font-size: 23px;
        margin-bottom: 25px;
      }

      span {
        margin: 0;
        font-size: 14px;
      }
    }

    .btn-off {
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0;
      width: 62.5px;
      height: 90px;
      border-radius: 4px;

      .btn-icon {
        font-size: 23px;
        margin-bottom: 25px;
      }

      span {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}
