.PersonalInfo {
  margin-bottom: 35px;

  .title {
    font-size: 13px;
    margin-bottom: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Card {
    box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);

    .CardContent {
      display: flex;
      justify-content: start;
      align-content: center;

      .icon {
        margin-right: 19px;
        width: 84px;
        height: 84px;
        flex-grow: 0;
        background-color: #20ace8;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #fff;
          font-size: 33px;
          text-transform: uppercase;
          font-weight: 600;
          font-family: GraphikLight !important;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.18px;
        }
      }

      .info {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        .name {
          font-size: 24px;
          font-weight: bolder;
          margin-right: 15px;
        }

        .role {
          font-size: 16px;
        }

        .email {
          margin-top: 6px;
          font-size: 16px;
        }
      }
    }
  }
}
