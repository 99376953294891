.HeaderDetails {
  margin-top: 22px;

  .DivDetail {
    .title {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 100;
      width: 173px;
    }

    .value {
      font-size: 18px;
      font-weight: normal;
      letter-spacing: -.835px;
    }

    .SetTempVal {
      margin-left: 18px;
      margin-right: 20px;
    }
  }

  .DivBtns {
    min-width: 342px
  }
  .BtnSnowSensor {
    text-transform: uppercase;
    letter-spacing: .154px;
    background-color: #f4f4f4;
    color: #9c9c9c;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 4px;
    border: solid 1px #e3e3e3;
    &:first-child {
      margin-right: 13px;
      margin-left: 25px;
    }
    &:last-child {
      margin-right: 0;
      margin-left: 13px;
    }

    height: 43px;
    min-width: 161px;
    width: 100%;

    span {
      margin-left: 8px;
      height: 16px;
      line-height: 17px;
    }
  }

  .BtnFan {
    color: #fff;
    text-transform: uppercase;
    border: 0;
    border-radius: 4px;
    background-color: #20ace8;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.442px;
    font-size: 13px;
    height: 43px;

    span {
      height: 16px;
      line-height: 17px;
      margin-left: 8px;
    }
  }

  .BtnFanWidth {
    min-width: 143px;
    width: 100%;
    margin-left: 13px;
  }
}

@media screen and (max-width: 1644px) {
  .HeaderDetails {
    flex-wrap: wrap;

    .DivDetail {
      .title {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 1143px) {
  .HeaderDetails {
    .Details1, .Details2 {
      margin-bottom: 33px;
    }
  }
}

@media screen and (max-width: 500px) {
  .HeaderDetails {
    margin-top: 19px;
    margin-bottom: 40px;

    .mr-2-625, .mr-2-75, .mr-2-5625 {
      margin-right: 16px !important;
    }

    .Details2 {
      flex-wrap: wrap;
      align-items: start !important;
      justify-content: start !important;

      .DivDetail {
        margin-bottom: 22px;
        width: 100%;

        .position-relative {
          background: #fff;
          padding: 8px 12px 8px;
        }
      }

      .DivBtns {
        align-items: start !important;
        justify-content: start !important;
        width: 100%;

        .BtnSnowSensor {
          margin-left: 0;
        }
      }
    }
  }
}
