.OtherSettings {
  .title {
    font-size: 13px;
    margin-bottom: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Card {
    box-shadow: 0 3px 20px 0 rgba(77, 80, 96, 0.06);
  }
}
