.GarphData {
  .SelectDevice {
    max-width: 130px !important;
    min-width: 130px !important;
    min-height: 34px !important;
  }

  .ScheduledOption {
    .SelectResponsive {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .DatePicker {
    height: 34px !important;
    max-width: 140px !important;
    background: transparent;
  }

  button {
    height: 39px;
  }
}

@media screen and (max-width: 768px) {
  .GarphData {
    .GarphDataDiv {
      flex-wrap: wrap;
    }

    .ScheduledOption {
      margin-top: 19px;
      flex-wrap: wrap;

      .SelectResponsive {
        width: 47%;

        .DatePicker {
          max-width: unset !important;
        }
      }

      .SelectDevice {
        width: 100% !important;
        min-width: unset !important;
        max-width: unset !important;
      }
    }
  }
}
