.Energy {
  min-width: 259px;

  .title {
    font-size: 12.09px;
    margin-bottom: 9px;
    color: var(--text-color-black);
  }

  .Card {
    box-shadow: 10px 3px 20px 0 rgba(77, 80, 96, 0.06);

    .Body {
      padding-right: 31px;

      .energy-icon {
        font-size: 22px;
        width: 22px;
        height: 28px;
      }

      .Content {
        margin-left: 11px;

        .title {
          font-size: 20px;
          font-weight: normal;
          margin: 0 !important;
          margin-bottom: 4px !important;
        }

        .paragraph {
          font-size: 13px;
          margin: 0;
        }
      }
    }
  }
}
